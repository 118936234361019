<template>
  <b-card no-body>
    <!--<b-card-text>{{selected}}</b-card-text>-->
    <b-row>
      <b-col md="6">
        <b-button v-if="$hascan('evento.registrar')" variant="primary" class="mr-1" :to="{ name: 'evento-nuevo' }">
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          <span class="align-middle">Nuevo</span>
        </b-button>
        <b-button @click="actualizarLista()" variant="secondary" class="btn-icon mr-1" >
          <feather-icon
            icon="RotateCcwIcon"
          />
        </b-button>
      </b-col>
      <!-- <b-col md="3">
        <v-select
          label="title"
          placeholder="Filtrar por tipo de entrada"
          v-model="tipo_entrada_id"
          :options="tipoEntradaOpciones"
        />
      </b-col> -->
      <b-col
        md="6"
      >
        <b-form-group
          label=""
          label-align-sm="right"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group>
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Buscar.."
            />
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12" class="my-2">
        <b-overlay
          :show="loading"
        >
          <b-table class="position-relative sm" responsive="sm" 
              ref="selectableTable"
              selectable
              select-mode="single" 
              hover
              striped
              :small="true"
              :per-page="perPage"
              :current-page="currentPage"
              :items="items" 
              :fields="fields"
              @row-selected="onRowSelected"
              show-empty
              empty-text="No se encontraron registros coincidentes"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              >
              <template #cell(selected)="{ rowSelected }">
                  <template v-if="rowSelected">
                    <i class="feather icon-disc primary" />
                  </template>

                  <template v-else>
                    <i class="feather icon-circle" />
                  </template>
              </template>

              <template #cell(entrada.titulo)="data">
                <h5><strong>{{ data.value }}</strong></h5>
                <strong class="text-primary">Url: </strong>{{ data.item.entrada.url }}
              </template>

              <template #cell(entrada.estado)="data">
                <b-badge :variant="estado[1][data.value]">
                  {{ estado[0][data.value] }}
                </b-badge>
              </template>

              <template #cell(accion)="data">
                <div class="text-nowrap">
                  <b-button
                    v-if="$hascan('evento.editar')"
                    @click="$router.push({ name: 'evento-editar', params: { id: data.item.entrada_id } })"
                    
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    v-b-tooltip.hover.bottom="'Editar'"
                    class="btn-icon mr-1"
                    size="sm"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>

                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-dark"
                    @click="imprimirEntradaPdf(data.item.entrada_id)"
                    v-b-tooltip.hover.bottom="'Imprimir PDF'"
                    class="btn-icon mr-1"
                    size="sm"
                  >
                    <feather-icon icon="PrinterIcon" />
                  </b-button>

                  <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-dark"
                      v-b-tooltip.hover.bottom="'Previsualizar'"
                      class="btn-icon mr-1"
                      target="_blank"
                      size="sm"
                      :href="`${mixing.app_url}evento/${data.item.entrada.url}?preview=${data.item.entrada.sha}`"
                    >
                      <feather-icon icon="EyeIcon" />
                  </b-button>

                  <b-button
                    v-if="$hascan('evento.papelera')"
                    @click="confirmar_mover_papelera(data.item.entrada_id)"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-danger"
                    v-b-tooltip.hover.bottom="'Papelera'"
                    class="btn-icon"
                    size="sm"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </div>
              </template>
              <template #cell()="data">
                <span class="text-nowrap">{{ data.value }}</span>
              </template>
          </b-table>
        </b-overlay>
      </b-col>
      <b-col cols="12">
          <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="right"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import axiosIns from '@/libs/axios'
import { BCard, BCardText, BTable, BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BDropdown, BDropdownItem, BOverlay, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from "vue-select";
//import axios from 'axios'
export default {
  components: {
    BCard,
    BCardText,
    BTable,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BOverlay,
    vSelect
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data(){
    return{
      items:[],
      fields: [
        {key: "entrada.tipo_entrada.nombre",label:"Tipo", sortable: false},
        { key: "entrada.titulo", label: "Titulo", sortable: false },
        { key: "fecha", label: "Fecha", sortable: false },
        { key: "ciudad", label: "Ciudad", sortable: false },
        { key: "nombre_lugar", label: "Lugar", sortable: false },
        { key: "nombre_organizador", label: "Organizador", sortable: false },
        { key: "entrada.estado", label: "Estado", sortable:false},
        { key: "entrada.user.name", label: "Autor", sortable: false },
        "accion",
      ],
      selected: [],
      filter: null,
      filterOn: [],
      perPage: 10,
      pageOptions: [10, 25, 50],
      totalRows: 1,
      currentPage: 1,
      loading:false,
      estado: [{
        1: 'Publicado', 2: 'Borrador', 3: 'Papelera',
      },
      {
        1: 'light-primary', 2: 'light-warning', 3: 'light-danger',
      }],
      item:[],
      categoria_id:'',
      categoriaOpciones: [],
      tipo_entrada_id:'',
      tipoEntradaOpciones: [],
      
    }
  },
  created(){        
    this.loading=true       
    this.listar();
    this.listarCategorias();
    this.listarTipoEntradas();
  },  
  watch: {
    categoria_id: function() {
      this.loading = true;
      this.listar();
    },
    tipo_entrada_id: function() {
      this.loading = true;
      this.listar();
    },
  },
  methods: {
    onRowSelected(items) {
      this.selected = items
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    selectThirdRow() {
      this.$refs.selectableTable.selectRow(2)
    },
    unselectThirdRow() {
      this.$refs.selectableTable.unselectRow(2)
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    listar(){
      
      var url= '/eventos';
      axiosIns.get(url)
        .then(res => {
          this.items=res.data.data;
          this.totalRows = this.items.length
          this.loading=false
        })
        .catch(err =>{
          console.log(err);
        });
    },
    actualizarLista(){
      this.loading=true;
      this.listar();
    },
    listarCategorias(){
      var url= '/admin/categorias';
      axiosIns.get(url)
        .then(res => {
          this.categoriaOpciones=res.data.map(g => ({title:g.nombre, value: g.id}));
        })
        .catch(err =>{
          console.log(err);
        });
    },
    listarTipoEntradas(){
      var url= '/admin/tipo-entradas-default';
      axiosIns.get(url)
        .then(res => {
          this.tipoEntradaOpciones=res.data.map(g => ({title:g.nombre, value: g.id}));
        })
        .catch(err =>{
          console.log(err);
        });
    },
    confirmar_mover_papelera(id) {
      this.$swal({
        title: "Advertencia",
        text: "Confirme que esta seguro de mover a Papelera?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.mover_papelera(id);
        }
      });
    },
    mover_papelera(id) {
      axiosIns.put('/admin/entradas/mover-papelera/'+id)
        .then(res => {
          this.$bvToast.toast('Exito', {
            title: 'Se movio a papelera correctamente',
            variant:'success',
            solid: false,
          })
          this.actualizarLista();
        }) 
        .catch(err =>{
          console.log(err);
        });
    },
    //imprimir pdf
    imprimirEntradaPdf(id){
        axiosIns({
            url: '/admin/entradas/imprimir-pdf/' + id,
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            var url = URL.createObjectURL(new Blob([response.data],{ type: 'application/pdf' }));
            var iframe = document.createElement('iframe');
            iframe.src = url;
            iframe.className='pdfIframe'
            iframe.style.display = 'none';
            iframe.style.visibility='hidden';
            document.body.appendChild(iframe);
            iframe.focus();
            iframe.contentWindow.print();
            URL.revokeObjectURL(url);
        })
        .catch(err =>{
            console.log(err);
        });
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>