<template>
  <b-card title="Eventos">
    <b-tabs>
      <b-tab lazy title="Publicado">
        <Publicado/>
      </b-tab>
      <b-tab lazy title="Borradores">
        <Borrador/>
      </b-tab>
      <b-tab lazy title="Programado">
        <Programado/>
      </b-tab>
      <b-tab lazy title="Papelera">
        <Papelera/>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>

import { BTabs, BTab, BCard } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import Publicado from '@/views/aplicaciones/eventos/tabs/Publicado.vue'
import Borrador from '@/views/aplicaciones/eventos/tabs/Borrador.vue'
import Programado from '@/views/aplicaciones/eventos/tabs/Programado.vue'
import Papelera from '@/views/aplicaciones/eventos/tabs/Papelera.vue'

export default {
  components: {
    BTabs,
    BTab,
    BCard,
    Publicado,
    Borrador,
    Programado,
    Papelera,
  },
  directives: {
    Ripple,
  },
  data() {
    return {

    }
  },
  created() {},
  watch: {
  },
  methods: {
  },
}
</script>
<style lang="scss"></style>
